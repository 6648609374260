var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "nav" },
    [
      _c("b-nav-item", [
        _c("li", { staticClass: "nav-item mr-auto mt-0" }, [
          _c(
            "span",
            { staticClass: "brand-logo" },
            [_c("b-img", { attrs: { src: _vm.appLogoImage, alt: "logo" } })],
            1
          )
        ])
      ]),
      _c("b-nav-item", [
        _c("li", { staticClass: "nav-item ml-2 mt-1" }, [
          _c("h4", { staticClass: "brand-text" }, [
            _vm._v(" " + _vm._s(_vm.appName) + " ")
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }